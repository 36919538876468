import React from 'react'
import { RichText } from 'prismic-reactjs'

import ArticleImage from './article-image'
import ArticleHeader from './article-header'
import GatsbyLink from './gatsby-link'
import styled from 'styled-components'

import { devices } from '../lib/constants'
import { StyledPlaceholder } from './styles'

const ArticleContent = styled.div`
  margin-left: 1rem;
`

const StyledExternalCard = styled.article`
  > a {
    display: flex;
    align-items: flex-start;
    color: inherit;
    text-decoration: none;

    &:hover {
      h3,
      footer span {
        color: var(--color-primary);
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  h3 {
    font-size: var(--font-size-h4);
    margin: 1.4rem 0 1.8rem 0;
    transition: color 0.3s;
  }

  span {
    color: inherit;
    transition: color 0.3s;
  }

  footer {
    color: var(--color-dark-grey);
  }

  figure,
  ${StyledPlaceholder} {
    min-width: 60px;
    overflow: hidden;
    border-radius: var(--border-radius-small);

    @media ${devices.laptop} {
      min-width: 100px;
    }
  }
`

const CardExternalArticle = ({ node }) => (
  <StyledExternalCard>
    <a href={node.data.source.url} target="_blank" rel="noreferrer">
      <ArticleImage
        image={node.data?.featured_image}
        alt={node.data.featured_image.alt || node.data.title.text}
        aspectRatio={{ width: 1, height: 1 }}
      />

      <ArticleContent>
        <ArticleHeader date={node.data.publication_date} tags={node.tags}>
          <h3>{node.data.title.text}</h3>
        </ArticleHeader>

        <RichText render={node.data.content.raw} serializeHyperlink={GatsbyLink} />

        <footer>
          Bron: <span>{node.data.source_name}</span>
        </footer>
      </ArticleContent>
    </a>
  </StyledExternalCard>
)

export default CardExternalArticle
