import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Pagination from '../components/pagination'
import CardExternalArticle from '../components/card-external-article'
import Seo from '../components/seo'
import { StyledGrid, StyledStickySidebar } from '../components/styles'
import CardArticlePinned from '../components/card-article-pinned'

const ExternalArticleOverviewTemplate = ({ pageContext, data }) => {
  const page = data.prismicPage
  const seoTitle =
    pageContext.currentPage > 1
      ? `${page.data.title.text} · Pagina ${pageContext.currentPage}`
      : page.data.title.text

  return (
    <Layout>
      <Seo title={seoTitle} />

      <h1 className="font-gradient-blue">{page.data.title.text}</h1>
      <StyledGrid columns="2">
        <div>
          {data.allPrismicExternalArticle.edges.map(({ node }) => (
            <CardExternalArticle key={node.id} node={node} />
          ))}
          <Pagination pageContext={pageContext} label="Meer kennis artikelen" />
        </div>
        {data.allPrismicArticle.edges.length && (
          <StyledStickySidebar>
            <CardArticlePinned node={data.allPrismicArticle.edges[0].node} />
          </StyledStickySidebar>
        )}
      </StyledGrid>
    </Layout>
  )
}

export default ExternalArticleOverviewTemplate

export const query = graphql`
  query ExternalArticleOverviewQuery($uid: String!, $limit: Int!, $skip: Int!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
      }
    }

    allPrismicExternalArticle(
      sort: { fields: data___publication_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            content {
              raw
            }
            featured_image {
              alt
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
            publication_date(formatString: "DD-MM-YYYY")
            source {
              url
            }
            source_name
          }
          tags
        }
      }
    }

    allPrismicArticle(
      sort: { fields: data___publication_date, order: DESC }
      filter: { data: { sticky: { eq: true } } }
      limit: 1
    ) {
      edges {
        node {
          data {
            ...cardOverviewDataFragment
          }
          tags
          uid
        }
      }
    }
  }
`
